@import './src/assets/styles/scss/variables';
@import './src/assets/styles/scss/mixins';

.input{
  @include reset-element;

  // -webkit-appearance: none;
  &:hover, &:focus{
    outline: $color-transparent;
  }
}
