@import "./src/assets/styles/scss/variables";
@import "./src/assets/styles/scss/mixins";

$size--small: 150px;
$size--default: 200px;

.container {
  display: inline-block;
  width: min-content;
}

.input {
  width: $size--small;

  .size--default>&{
    width: $size--default;
  }
}

.dropdown {
  position: absolute;
  width: $size--small;
  z-index: $z-index-input-dropdown;
  background-color: $color-white;
  border-radius: 4px;
  border: 1px solid $color-claydivider;
  box-shadow: 0 0 1px $color-lighterblackshadow, 0 2px 10px $color-lightblackshadow;
  max-height: 400px;
  overflow-y: scroll;

  .size--default>&{
    width: $size--default;
  }
}

.suggestions {
  padding: 10px 12px;
}

.suggestion {
  margin-bottom: 0;
  padding: 10px 12px;

  &:hover {
    background-color: $color-claybackground;
  }
  @include limit-displayed-text;
}
