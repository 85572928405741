@import "./src/assets/styles/scss/variables";

.button {
  display: inline-block;
  background-color: white;
  min-width: 24px;
  width: fit-content;
  min-height: 22px;
  padding: 1px 0 0 ;
  margin: 0 2px;
  border: 1px solid;
  border-radius: 4px;
  color: $color-gray;
  font-size: 12px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: $color-blue;
    color: $color-white;
  }
}

.button--active {
  background-color: $color-blue;
  color: $color-white;
}