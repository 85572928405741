@import './src/assets/styles/scss/variables';
@import './src/assets/styles/scss/mixins';

$button3D-height: 46px;

// pl-btn general style
.pl-btn{
  height: $button3D-height;
  @include reset-element;

  color: $color-white;
  @include font-family($font-opensans, semibold);

  font-size: 18px;
  line-height: 22px;
  text-align: center;
  padding: 6px 21px;
  border-radius: 5px;
  letter-spacing: 0;
  cursor: pointer;

  // states
  &:hover, &:focus, &:active{
    outline: rgb(0 0 0 / 0%);
  }

  &:active{
    transform: translateY(2px);
  }
}

// pl-btn classes

// pl-btn-primary
.pl-btn-primary{
  background-color: $color-blue;
  text-shadow: $text-shadow-primary;

  &:hover, &:focus, &:active{
    background-color: $color-blue;
  }
}

.pl-btn-primary.success{
  color: $color-blue;
  background-color: $color-transparent;
  transform: translateY(2px);
  cursor: default;
  text-shadow: none;
}

// END pl-btn-primary

.pl-btn-secondary{
  color: $color-red;

  &:hover, &:focus, &:active{
    color: $color-darkred;
    box-shadow: none;
  }
}

.pl-btn-secondary.success{
  color: $color-red;
  background-color: $color-transparent;
  transform: translateY(2px);
  cursor: default;

  &:hover, &:focus, &:active{
    box-shadow: none;
  }
}
