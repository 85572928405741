@import "./src/assets/styles/scss/variables";
@import "./src/assets/styles/scss/mixins";

.pl-navbar{
  background-color: $color-white;
  position: fixed;
  width: calc(100vw - #{$side-nav-width});
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $nav-height;
  @include padding-horizontal(30px);
  border-bottom: 1px solid $color-claydivider;
  z-index: $z-index-navbar;
}

.children {
  display: flex;
  align-items: center;
}

.pl-navbar__right__name{
  color: $color-blue;
}
