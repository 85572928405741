@import "./variables";

@import "./node_modules/@pluralcom/blueprint/dist/styles/scss/mixins";

@mixin limit-displayed-text() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin reset-element() {
  /* Reset's every elements apperance */
  background: none repeat scroll 0 0 transparent;
  border: medium none;
  border-spacing: 0;
  list-style: none outside none;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-indent: 0;

  &:hover, &:focus, &:active {
    background: inherit;
    outline: transparent;
  }

  appearance: none;
}

@mixin reset-button() {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;

  &[disabled]{
    cursor: unset;
  }
  @include outline-transparent;
}

@mixin color-a-complete($color, $colorHover: $color, $colorFocus: $colorHover, $colorActive: $colorHover) {
  color: $color;

  &:hover {
    color: $colorHover;
  }

  &:focus {
    color: $colorFocus;
  }

  &:active {
    color: $colorActive;
  }

  // overriding bootstrap@4.0.0-alpha.6
  &:not([href], [tabindex]){
    color: $color;

    &:hover {
      color: $colorHover;
    }

    &:focus {
      color: $colorFocus;
    }

    &:active {
      color: $colorActive;
    }
  }
}

@mixin reset-a() {
  text-decoration: none;

  &:hover, &:focus, &:active {
    text-decoration: unset;
    outline: $color-transparent;
  }
}

@mixin reset-border() {
  border: 0;
  border-radius: 0;
}

@mixin font-size-lineheight-recommended($fontSize) {
  font-size: $fontSize;
  line-height: calc(#{$fontSize} * #{$line-height-base});
}

@mixin font-size-lineheight-input() {
  font-size: 18px;
  line-height: 22px;
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin max-size($width, $height: $width) {
  max-width: $width;
  max-height: $height;
}

@mixin min-size($width, $height: $width) {
  min-width: $width;
  min-height: $height;
}

@mixin circle($radius: null) {
  @if $radius == null{} @else{
    @include size($radius);
  }

  border-radius: 50%;
}

@mixin margin-bottom-except-last($value, $lastValue: 0) {
  margin-bottom: $value;

  &:last-child{
    margin-bottom: $lastValue;
  }
}

@mixin margin-right-except-last($value, $lastValue: 0) {
  margin-right: $value;

  &:last-child{
    margin-right: $lastValue;
  }
}

@mixin margin-top-except-first($value, $firstValue: 0) {
  margin-top: $value;

  &:first-child{
    margin-top: $firstValue;
  }
}

@mixin all-direct-children($selector: null) {
  @if $selector{
    &>#{$selector}{
      @content;
    }
  } @else{
    &>*{
      @content;
    }
  }
}

@mixin hover-focus__outline-transparent() {
  &:hover, &:focus{
    outline: $color-transparent;
  }
}

@mixin outline-transparent() {
  &:hover, &:focus, &:active{
    outline: $color-transparent;
  }
}

@mixin position-fixed-fullscreen() {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@mixin a-text-decoration($textDecoration) {
  text-decoration: $textDecoration;

  &:not([href], [tabindex]){
    text-decoration: $textDecoration;
  }
}

@mixin background-cover-center() {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@mixin background-contain-center() {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

@mixin responsive-background-image($backgroundImage, $imageWidth, $imageHeight, $containerWidth:100%) {
  background-image: $backgroundImage;
  padding-top: calc(#{$imageHeight} / #{$imageWidth} * #{$containerWidth});
  background-size: contain;
  background-repeat: no-repeat;
  width: $containerWidth;
  height: 0;
}

@mixin padding-vertical($top, $bottom: $top){
  padding-top: $top;
  padding-bottom: $bottom;
}

@mixin padding-horizontal($left, $right: $left){
  padding-left: $left;
  padding-right: $right;
}

@mixin margin-vertical($top, $bottom: $top){
  margin-top: $top;
  margin-bottom: $bottom;
}

@mixin margin-horizontal($left, $right: $left){
  margin-left: $left;
  margin-right: $right;
}

@mixin border-vertical($top, $bottom: $top){
  border-top: $top;
  border-bottom: $bottom;
}

@mixin border-horizontal($left, $right: $left){
  border-left: $left;
  border-right: $right;
}

@mixin border-top-radius($raidus){
  border-top-right-radius: $raidus;
  border-top-left-radius: $raidus;
}

@mixin border-bottom-radius($raidus){
  border-bottom-right-radius: $raidus;
  border-bottom-left-radius: $raidus;
}

@mixin positioning($top, $right: $top, $bottom: $top, $left: $right){
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin center-vertical-pos(){
  top: 50%;
  transform: translateY(-50%);
}

@mixin font-family($font-family-map, $font-type: regular, $font-style: null){
  font-family: map-get($font-family-map, $font-type);
  font-weight: map-get($font-weights, $font-type);
  @if $font-style{
    font-style: $font-style;
  }
}

@mixin font-family-full($font-family-map, $font-type: regular, $font-style: null){
  @include font-family($font-family-map, $font-type, $font-style);

  strong, b{
    @include font-family($font-family-map, $font-type: bold);
  }

  em, i{
    @include font-family($font-family-map, regular, italic);
  }
}

@mixin font-inherit(){
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

@mixin cursor-drabable(){
  cursor: grab;

  &:active{
    cursor: grabbing;
  }
}

@mixin textarea-style(){
  margin-top: 40px;
  width: 100%;
  color: $color-black;
  caret-color: $color-blue;
  font-family: $font-opensans-regular;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0;

  &::placeholder{
    color: $color-gray;
  }
}

@mixin indicator-style($custom-padding-bottom) {
  position: absolute;
  bottom: $custom-padding-bottom;
  text-align: center;
}

@mixin absolute-zero() {
    position: absolute;
    @include positioning(0, 0, 0, 0);
}

@mixin fixed-zero() {
    position: fixed;
    @include positioning(0, 0, 0, 0);
}

@mixin contain-image() {
  width: unset;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

@mixin text-ellipses {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/** 
 * Pass pointer events through wrapper to underlaying layers
 * While Capturing the events of children normally
 */
@mixin passthrough-pointer-events {
  pointer-events: none;

  & > *{
    pointer-events: auto;
  }
}
