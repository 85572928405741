@import "./src/assets/styles/scss/variables";
@import "./src/assets/styles/scss/mixins";

.content {
  width: max-content;
  min-width: 100%;
  @include padding-vertical($nav-height);
}

.space {
  width: 30px;
}

.btnLoadMore {
  width: 120px;
  margin: 1em 2em;
}

.list {
  overflow: auto;

  &_item {
    padding: 0;
  }
}
