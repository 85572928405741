@import "./variables";

:root {
  /* Colors */
  --blue: #{$color-blue};
  --red: #{$color-red};
  --signalgreen: #{$color-signalgreen};
  --green: #{$color-green};
  --yellow: #{$color-yellow};
  --mediumyellow: #{$color-mediumyellow};
  --brightyellow: #{$color-brightyellow};
  --backgroundgray: #{$color-backgroundgray};
  --lightbackgroundgray: #{$color-lightbackgroundgray};
  --superdarkgray: #{$color-superdarkgray};
  --darkergray: #{$color-darkergray};
  --darkgray: #{$color-darkgray};
  --buttongray: #{$color-buttongray};
  --lightergray: #{$color-lightergray};
  --lightgray: #{$color-lightgray};
  --mediumgray: #{$color-mediumgray};
  --gray: #{$color-gray};
  --darkblue: #{$color-darkblue};
  --darkbluemedium: #{$color-darkbluemedium};
  --blueshadow: #{$color-blueshadow};
  --darkred: #{$color-darkred};
  --white: #{$color-white};
  --black: #{$color-black};
  --claybackground: #{$color-claybackground};
  --claydivider: #{$color-claydivider};

  /* New colors */
  --almostblack: #{$color-almostblack};

  /* Web */
  --lightbackgroundgrayblue: #{$color-lightbackgroundgrayblue};
  --transparent: #{$color-transparent};

  /* 3rd party brands */
  --brand-twitter: #{$color-brand-twitter};
  --brand-facebook: #{$color-brand-facebook};

  /* Grid-breakpoints */
  --breakpoint-xs: #{map-get($grid-breakpoints, xs)};
  --breakpoint-sm: #{map-get($grid-breakpoints, sm)};
  --breakpoint-md: #{map-get($grid-breakpoints, md)};
  --breakpoint-lg: #{map-get($grid-breakpoints, lg)};
  --breakpoint-xl: #{map-get($grid-breakpoints, xl)};
  --breakpoint-xxl: #{map-get($grid-breakpoints, xxl)};

  /* Fonts */
  --font-family-fallback-sans-serif: #{$font-stack-fallback};
  --font-family-opensans-bold: #{$font-opensans-bold};
  --font-family-opensans-regular: #{$font-opensans-regular};
  --font-family-opensans-semibold: #{$font-opensans-semibold};

  /* Bootstrap specific overides */
  --primary: #{$color-blue};
  --secondary: #{$color-gray};
  --info: #{$color-blueshadow};
  --warning: #{$color-yellow};
  --danger: #{$color-red};
  --font-family-sans-serif: #{$font-stack-fallback};
}