@import './src/assets/styles/scss/variables';
@import './src/assets/styles/scss/mixins';

.a{
  cursor: pointer;
  text-decoration: unset;

  &:hover, &:focus, &:active {
    text-decoration: unset;
    outline: $color-transparent;
  }
}

.transparent{
  color: $color-lightgray;
  @include font-size-lineheight-recommended(16px);

  &:hover, &:focus{
    text-decoration: none;
  }

  &:focus{
    color: $color-lightgray;
  }
}

.clay{
  color: $color-blue;
  @include font-family($font-opensans, semibold);

  font-size: 17px;
  line-height: 26px;
  letter-spacing: -0.4px;
}

.inherit-color{
  @include color-a-complete(inherit);
}

.inherit-font{
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}
